import Button from '../../common/components/Button';

/**
 * The `LogInButton` component displays a social login button which will open
 * a popup window containing the specified path.
 *
 * ### Attrs
 *
 * - `path`
 */
export default class LogInButton extends Button {
  static initAttrs(attrs) {
    attrs.className = (attrs.className || '') + ' LogInButton';

    attrs.onclick = function () {
      const width = 580;
      const height = 400;
      const $window = $(window);

      alert('teste')
    };

    super.initAttrs(attrs);
  }
}
