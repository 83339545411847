import Modal from '../../common/components/Modal';
import Button from '../../common/components/Button';
import extractText from '../../common/utils/extractText';
import ItemList from '../../common/utils/ItemList';
import Stream from '../../common/utils/Stream';

/**
 * The `LogInModal` component displays a modal dialog with a login form.
 *
 * ### Attrs
 *
 * - `identification`
 * - `password`
 */
export default class LogIlluminated extends Modal {
  oninit(vnode) {
    super.oninit(vnode);
  }

  className() {
    return 'LogInModal Modal--large Modal-custom';
  }

  title() {
    return 'Método Iluminado';
  }

  content() {
    return [<div className="Modal-body Modal-body-ext">{this.body()}</div>, <div className="Modal-footer">{this.footer()}</div>];
  }

  body() {

    
    return (
      <div>
        <p class="pricingMessage">Garanta já seu acesso a nossa plataforma e tenha acesso ao método
      completo com mais de 200 aulas e conteúdos exclusivos.</p>
      <div class="planContainer">
        
  <div class="plan">
    <div class="titleContainer">
      <div class="title">MENSAL</div>
    </div>
    <div class="infoContainer">
      <div class="price">
        <small class="smallInfo">R$</small><p> 199,90</p>
      </div>
      <div class="p desc"><em>renovação mensal</em></div>
      <hr />
      <ul class="features">
        <li>Acesso à todas a aulas</li>
        <li>Lives com mentores</li>
        <li>Suporte exclusivo</li>
        <li>Acesso à comunidade</li>
      </ul>
      <a class="selectPlan" href="https://ev.braip.com/checkout/pladoxo4/che2x57e">Garanta já sua vaga</a>
    </div>
  </div>
  <div class="plan">
    <div class="titleContainer">
      <div class="title">ANUAL</div>
    </div>
    <div class="infoContainer">
      <div class="price">
        <small class="smallInfo">12x</small><p> 150,30</p>
      </div>
      <div class="p desc"><em>ou R$ 1497,00 à vista</em></div>
      <hr />
      <ul class="features">
        <li>Acesso à todas a aulas</li>
        <li>Lives com mentores</li>
        <li>Suporte exclusivo</li>
        <li>Acesso à comunidade</li>
        <li>Benefícios exclusivos</li>
      </ul>
      <a class="selectPlan" href="https://ev.braip.com/checkout/pla6020w/che2x57e">Garanta já sua vaga</a>
    </div>
  </div>
  <div class="plan planInverted">
    <div class="titleContainer">
      <div class="title">VITÁLICIO</div>
    </div>
    <div class="infoContainer">
      <div class="price textInverted">
        <small class="smallInfo">12x</small><p class="textInverted"> 250,70</p>
      </div>
      <div class="p desc textInverted"><em class="textInverted">ou R$ 2497,00 à vista</em></div>
      <hr />
      <ul class="features textInverted">
        <li>Acesso à todas a aulas</li>
        <li>Lives com mentores</li>
        <li>Suporte exclusivo</li>
        <li>Acesso à comunidade</li>
        <li>Benefícios exclusivos</li>
        <li>Acesso à todas atualizações</li>
        <li>Acesso Vitálicio</li>
      </ul>
      <a class="selectPlan selectPlanInverted" href="https://ev.braip.com/checkout/plapvjq8/che2x57e">Garanta já sua vaga</a>
    </div>
  </div>
</div>
</div>
      );
  }

  footer() {
    const baseUrl = window.location.host === 'cmstaging.metodoiluminado.com.br' ? 'https://staging.metodoiluminado.com.br' : 'https://metodoiluminado.com.br';

    return [
      <p class="loginMessage">Se você já possui uma assinatura, clique no botão abaixo para acessar a plataforma.</p>,
      <a className="Button-label Login-Label Button Button-login" href={baseUrl+'/login'} >Fazer login</a>
    ];
  }
}
